<template>
  <div class="activity_container" :style="`background: ${info.bj_color}`">
    <!-- <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div> -->
    <!-- $router.push('/index/skill') -->
    <div v-if="info.adv_img" class="img" @click="adJump">
      <img :src="info.adv_img" alt="" />
    </div>
    <div class="active-content">
      <div class="head">
        <div class="tab-box">
          <div :class="['tab-item', activeInd == index ? 'active' : '']" @click="changeTab(item, index)" v-for="(item, index) in tabList" :key="index">{{ item.title }}</div>
        </div>
      </div>
      <div class="content-center">
        <div class="nav-box">
          <div :class="['nav-item', navActiveInd == index ? 'active' : '']" @click="changeNav(item, index)" v-for="(item, index) in navList" :key="index">{{ item.title }}</div>
        </div>
        <div class="serach_box">
          <el-input placeholder="请输入搜索内容 名称/厂家" clearable v-model="p.kw" @keyup.enter.native="handleClick" class="input-w"> </el-input>
          <div class="search_str" @click="handleClick">搜索</div>
        </div>
        <div class="goods_wrap">
          <div class="item" v-for="k in activeData" :key="k.id">
            <bigimg :itemData="k" />
          </div>
          <div class="empty">
            <el-empty v-if="!activeData.length" :image-size="200"></el-empty>
          </div>
        </div>
        <pagination :total="total" :currentPage="page" :pageSize="page_size" :pageSizes="[20, 40, 80, 120, 200]" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
      </div>
    </div>

    <!--左侧导航  -->
    <!-- <floornav :list="zone" /> -->
  </div>
</template>

<script>
import bigimg from "@/components/goodsItem/bigImg.vue";
// import floornav from "@/components/floornav.vue";
import { handleJump } from "@/utils";
import pagination from "@/components/pagination.vue";
export default {
  components: {
    bigimg,
    // floornav,
    pagination,
  },
  data() {
    return {
      handleJump,
      zone: [],
      activeData: [],
      p: {
        kw: "",
        area_id: "",
        cate_id: "",
      },
      activeInd: 0,
      navActiveInd: 0,
      page: 1,
      page_size: 20,
      total: 0,
      pageName: this.$store.state.pageName,
      info: {
        adv_img: "",
      },
      tabList: [],
      navList: [],
    };
  },
  created() {
    this.getADV();
    this.getActiveCateTop();
    this.getActiveCateSecond();
  },
  methods: {
    adJump() {
      if (this.adData.pcJump.value) {
        this.$router.push(this.adData.pcJump.value);
      }
    },
    getADV() {
      this.$api("zone.toolsBanner", { type: 4 }).then((res) => {
        if (res.code == 200) {
          this.adData = res.data;
          this.info.adv_img = res.data.logo;
        }
      });
    },
    // 一级分类
    getActiveCateTop() {
      this.$api("goods.activeTop").then((res) => {
        if (res.code == 200) {
          this.tabList = res.data;
          this.p.area_id = this.tabList[0].id;
          this.getGoodsList();
        }
      });
    },
    // 二级分类
    getActiveCateSecond() {
      this.$api("goods.activeCate").then((res) => {
        if (res.code == 200) {
          this.navList = res.data;
          let catr_arr = res.data[0].jump_options.split("=");
          this.p.cate_id = catr_arr[1];
          // this.getGoodsList();
        }
      });
    },
    getGoodsList() {
      this.$api("goods.getGoods", { ...this.p, page: this.page, page_size: this.page_size }).then((res) => {
        if (res.code == 200) {
          this.total = res.data.count;
          // this.activeData = res.data.list;
          // 给商品设置number数据
          this.activeData = res.data.list.map((v) => {
            this.$set(v, "number", v.min_num);
            return v;
          });
        }
      });
    },
    changeTab(item, index) {
      this.page = 1;
      this.p.area_id = item.id;
      this.p.cate_id = "";
      this.kw = "";
      this.activeInd = index;
      this.navActiveInd = 0;
      this.getGoodsList();
    },

    changeNav(item, index) {
      this.page = 1;
      let id_arr = item.jump_options.split("=");
      this.p.cate_id = id_arr[1];
      this.navActiveInd = index;
      this.getGoodsList();
    },
    handleClick() {
      this.page = 1;
      this.getGoodsList();
    },

    handleSizeChange(val) {
      this.page_size = val;
      this.getGoodsList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getGoodsList();
    },
  },
};
</script>

<style lang="less" scoped>
.activity_container {
  background-size: cover;
  .img {
    // width: 100%;
    height: 220px;
  }
  .active-content {
    width: 100%;
    // padding-top: 30px;
    .head {
      width: 100%;
      background-color: #fa6c4c;
      .tab-box {
        width: 1200px;
        height: 80px;
        margin: 0 auto;
        display: flex;
        // justify-content: center;
        justify-content: flex-start;
        .tab-item {
          // width: 300px;
          // padding: 0 20px;
          color: #fff;
          font-size: 28px;
          box-sizing: border-box;
          padding: 0 20px;
          line-height: 80px;
          text-align: center;
          cursor: pointer;
        }
        .active {
          color: #fa6c4c;
          background-color: #fff;
        }
      }
    }
    .content-center {
      width: 1200px;
      margin: 0 auto;
      .nav-box {
        width: 100%;
        height: 68px;
        background-color: #fa6c4c;
        margin-top: 20px;
        border-radius: 68px;
        overflow: hidden;
        display: flex;
        .nav-item {
          width: 200px;
          height: 68px;
          box-sizing: border-box;
          text-align: center;
          line-height: 68px;
          font-size: 22px;
          font-weight: 700;
          color: #fff;
          cursor: pointer;
        }
        .active {
          background-color: #fff;
          border-radius: 68px;
          color: #fa6c4c;
          border: 1px solid #fa6c4c;
        }
      }
      .serach_box {
        height: 34px;
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: flex-end;
        .input-w {
          width: 210px;
          height: 34px;
          border: 1px solid #fa6c4c;
          ::v-deep .el-input__inner {
            width: 210px;
            height: 34px;
            border: none;
          }
        }
        .box {
          z-index: 1000;
          position: absolute;
          top: 60px;
          background: #fff;
          width: 190px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          padding: 0 10px;
          max-height: 450px;
          overflow-y: auto;
          line-height: 1.6;
          div {
            cursor: pointer;
            &:hover {
              color: @themeColor;
              // color: #fa6c4c;
            }
          }
        }
        .search_str {
          cursor: pointer;
          width: 40px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: @themeColor;
          background-color: #fa6c4c;
          font-size: 12px;
          color: #ffffff;
          border: 1px solid #fa6c4c;
        }
      }
    }
  }
  .title {
    width: 100%;
    background-color: #fff;
    height: 58px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    box-sizing: border-box;
    padding: 0 20px;
    // margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
  }
  .goods_wrap {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    // margin: 8px 0;
    margin-bottom: 10px;
    margin-top: 10px;
    .item {
      margin-right: 12px;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }

    .empty {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
